<template>
  <div class="dropdown" :class="{ expanded: isExpanded }">
    <div class="checkedItem" @click="toggleExpandHandler">
      <slot name="listItem" :option="value"></slot>
      <img src="/img/Chevron.png" :class="{ expanded: isExpanded }" alt="Chevron" />
    </div>
    <transition name="el-zoom-in-top">
      <div v-show="isExpanded" class="menuList">
        <div
          v-for="item in options.filter((option) => option !== value)"
          :key="`${item}`"
          class="menuListItem"
          @click="
            () => {
              listItemClickHandler(item);
            }
          "
        >
          <slot name="listItem" :option="item"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  options: unknown[];
  defaultValue?: string;
}>();

const value = ref(props.defaultValue);
const isExpanded = ref(false);
const emit = defineEmits(['handleChange']);

const listItemClickHandler = (item: unknown) => {
  emit('handleChange', item);
  value.value = item as string;
  isExpanded.value = false;
};

const toggleExpandHandler = () => {
  isExpanded.value = !isExpanded.value;
};

watch(
  () => props.defaultValue,
  (tokenValue) => {
    value.value = tokenValue as string;
  }
);
</script>
<style lang="scss" scoped>
.dropdown {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #1e353e;
  background: radial-gradient(92.39% 62.21% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 9;

  &.expanded {
    border-radius: 12px 12px 0 0;
  }
}

.checkedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 38px;
  height: 92px;
}

.checkedItem img {
  &.expanded {
    transform: rotate(180deg);
  }
}

.menuList {
  position: absolute;
  top: 90px;
  left: -2px;
  width: calc(100% + 4px);
  border: 2px solid #1e353e;
  border-radius: 0 0 12px 12px;
  overflow-x: hidden;
}

.menuListItem {
  width: 100%;
  height: 92px;
  background: #06151c;
  padding: 0 32px 0 38px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 2px solid #1e353e;
  }
}
</style>
