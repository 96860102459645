<template>
  <div class="tokenItemWrapper" :class="{ selected }">
    <ClientOnly>
      <img width="60" :src="tokenImg" />
      <h3 v-if="selected" class="tokenName">
        {{ addSpaces(currentToken) }}
      </h3>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { addSpaces } from '~/utils/formatters';
import { useMainStore } from '~/stores/mainStore';
import type { Tokens } from '~/utils/constants';
import { tokensConfig } from '~/utils/constants';

const props = defineProps<{
  token: Tokens;
}>();

const store = useMainStore();
const currentToken = computed(() => store.currentToken);
const selected = computed(() => props.token === currentToken.value);

const tokenImg = tokensConfig[props.token]?.media;
</script>

<style lang="scss" scoped>
.tokenItemWrapper {
  border-radius: 12px;
  border: 1px solid #1e353e;
  background: radial-gradient(92.39% 62.21% at 84.01% 85.82%, #040f14 0%, #081b25 100%);
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.25);
  padding: 1em;
  min-width: 95px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;

  &:hover:not(.selected) {
    background: radial-gradient(102.4% 102.35% at 57.35% 110.94%, #0083ad 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:not(.selected) {
    cursor: pointer;
  }
}
.selected {
  width: 260px;
  padding-left: 5px;
}

.tokenName {
  color: #fff;

  &::first-letter {
    text-transform: uppercase;
  }
}
</style>
