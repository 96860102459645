<template>
  <div ref="containerRef" class="main-cards wrapper">
    <h1 v-if="props.hasTitle" class="title">{{ $t('myTokensTitle') }}</h1>
    <div class="flexContainer">
      <CustomDropdown
        v-if="$device.isMobile"
        :options="options"
        :default-value="defaultDropdownValue"
        @handle-change="dropdownChangeHandler"
      >
        <template #listItem="slotProps">
          <h3 class="tokenName">
            <img width="45" :src="tokensConfig[slotProps.option as Tokens].media" />
            {{ addSpaces(tokensConfig[slotProps.option as Tokens].methodName) }}
          </h3>
        </template>
      </CustomDropdown>
      <div v-else class="tokensContainer">
        <TokenItem
          v-for="token in filteredTokenData"
          :key="token.key"
          :selected="token.key === store.currentToken"
          :token="token.key"
          @click="() => tokenClickHandler(token.key)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useFetch, useRoute, useRouter } from 'nuxt/app';
import { computed, ref, watch, defineProps } from 'vue';
import useEnvs from '~/composables/useEnvs';
import { addSpaces } from '~/utils/formatters';
import { useMainStore } from '~/stores/mainStore';
import type { ITokenModel } from '~/types/apiService';
import { Tokens, apiUrls, tokensConfig, path } from '~/utils/constants';

const route = useRoute();
const router = useRouter();

const props = withDefaults(
  defineProps<{
    hasTitle?: boolean;
    showSellable?: boolean;
    isDashboard?: boolean;
  }>(),
  {
    hasTitle: true,
    showSellable: true,
    isDashboard: false
  }
);

const { apiUrl } = useEnvs();

const tokensQuery = props.isDashboard
  ? {
      logic: 'or',
      sellable: 'true',
      buybackEnabled: 'true'
    }
  : {};
const { data: tokensData } = useFetch<ITokenModel[]>(apiUrls.token.tokens, {
  query: tokensQuery,
  baseURL: apiUrl,
  immediate: true,
  server: false
});

const store = useMainStore();
const options = computed(() => {
  if (!tokensData.value?.length) return [];

  const filtered = props.showSellable ? tokensData.value : tokensData.value?.filter((token) => token.isSellable);
  return filtered.map((token) => token.key);
});
const filteredTokenData = computed(() => {
  if (!tokensData.value?.length) return;

  return props.showSellable ? tokensData.value : tokensData.value?.filter((token) => token.isSellable);
});

const value = ref();
const containerRef = ref<HTMLDivElement>();
const defaultDropdownValue = ref<Tokens>(store.currentToken);

const dropdownChangeHandler = (checkedvalue: Tokens) => {
  value.value = checkedvalue;
};

watchEffect((onCleanup) => {
  let timeout: ReturnType<typeof setTimeout>;

  if (route.query.scrollToContainer && containerRef.value) {
    defaultDropdownValue.value = store.currentToken;

    timeout = setTimeout(() => {
      containerRef.value?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
    router.replace(path.public.dashboard);
    return;
  }

  onCleanup(() => {
    clearTimeout(timeout);
  });
});

watch(value, () => {
  store.setCurrentToken(value.value);
});

onBeforeUnmount(() => {
  store.setCurrentToken(Tokens.pearl);
});

const tokenClickHandler = (token: Tokens) => {
  const selected = token === store.currentToken;

  if (selected) {
    return;
  }

  value.value = token;
};
</script>

<style scoped lang="scss">
.main-cards {
  margin-bottom: 25px;
}

.wrapper {
  border: none;
  background: inherit;
}

.title {
  color: #fff;
  width: 100%;
  text-align: center;
  font-size: 64px;

  @media screen and (max-width: 990px) {
    font-size: 48px;
  }
}
.tokensContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  align-items: center;
  margin: 0 auto;
  overflow: auto;
}

.flexContainer {
  display: flex;
}
.tokenName {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
